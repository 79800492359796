import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

Vue.config.productionTip = false;
import localstore from "store";

Vue.prototype.$eventHub = new Vue(); // Global event bus

new Vue({
  router,
  render: (h) => h(App),
  data: {
    modal_is_open: false,
    opened_balises: [],
    balise_in_range: undefined,
    is_narrow_screen: false,

    publicPath: process.env.BASE_URL,

    show_welcome_modal: false,
    mode: "",
  },
  watch: {
    opened_balises() {
      localstore.set("opened_balises", this.opened_balises);
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);

    this.opened_balises = localstore.get("opened_balises")
      ? localstore.get("opened_balises")
      : [];
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.is_narrow_screen = window.innerWidth < 780;
    },
  },
}).$mount("#app");
